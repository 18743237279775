<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop='name' label='分类名称'>
        <el-input v-model="form.data.name" placeholder="请输入分类名称" maxlength="20" clearable></el-input>
      </el-form-item>
      <el-form-item prop='sort' label='排序'>
        <el-input v-model="form.data.sort" placeholder="数字越小排序越靠前" maxlength="20" clearable 
        @input="form.data.sort=form.data.sort.replace(/[^\d]/g,'')"></el-input>
      </el-form-item>
      <el-form-item prop='sort_num' label='列表排序'>
        <el-input v-model="form.data.sort_num" placeholder="数字越小排序越靠前" maxlength="20" clearable @input="form.data.sort_num=form.data.sort_num.replace(/[^\d]/g,'')"></el-input>
      </el-form-item>
      <el-form-item prop='image' label='上传图标'>
        <el-upload
          class="avatar-uploader-login"
          action=""
          :headers="{ token: token }"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="httpRequest">
          <img v-if="form.data.image" :src="form.data.image" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div class="el-upload__tip" slot="tip">建议尺寸：100*100像素</div>
        </el-upload>
      </el-form-item>
      <!-- <el-form-item prop='image' label='图片裁剪'>
        <my-vue-cropper
          fileRule="RICH_TEXT_IMG"
          :fileShow="false"
          :fileDisabled="fileDisabled"
          @crop-success="cropSuccess"
        />
      </el-form-item> -->
      <el-form-item prop='status' label='状态'>
        <el-switch
          v-model="form.data.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2">
        </el-switch>
      </el-form-item>

    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>

    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img v-if="dialogVisible" width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </el-dialog>
</template>

<script>
  import MyVueCropper from "@/components/MyVueCroppers.vue";
  import common from '@/util'
  export default {
    name: 'BannerEdit',
    components: {
      MyVueCropper
    },
    data() {
      return {
        fileDisabled: false,
        imgSize: 0,
        token: '',
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            parent_id: '',
            name: '',
            sort: '',
            image: '',
            status: 1,
            sort_num: '', // 排序2
          },
          rules: {
            name: [{ required: true, message:'请输入分类名称', trigger: 'change' }],
            sort: [{ required: true, message:'请输入排序', trigger: 'change' }],
            status: [{ required: true, message:'必选项', trigger: 'change' }],
            image: [{ required: true, message: '请上传图标', trigger: 'change' }],
          }
        },
        dialogVisible: false,
        dialogImageUrl: [],

      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true;
        this.$nextTick(() => {
          common.deepClone(this.form.data, row);
        })
      },
      reset(done) {
        this.isChange = false;
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show;
        !show && this.reset();
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let apiUrl = !this.isChange ? '/admin/flex_kind/add' : '/admin/flex_kind/edit'
            this.form.data.status = this.form.data.status == true ? 1 : 2
            this.form.loading = true;
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      // 上传主图
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.form.data.image = res.data.all_path_url;
          this.$refs.elFormDom.clearValidate();
        })
      },
      cropSuccess(data) {
        this.imgSize += 1;
        if (this.imgSize === 9) {
          this.fileDisabled = true;
        }
        this.imgUrl = data.all_path_url;
      },
    }
  }
</script>

<style scoped>
.avatar-uploader-login .el-icon-plus {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader-login .avatar-uploader-icon{
  line-height: 100px !important;
}
.avatar-uploader-login .avatar {
  width: 100px;
  height: 100px;
}
.avatar-uploader-image .el-icon-plus {
  width: 234px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader-image .avatar {
  width: 234px;
  height: 100px;
}
.avatar-uploader-image .avatar-uploader-icon{
  line-height: 100px !important;
}
.el-upload__tip {
  color: #f56c6c;
  margin-top: 0;
}
</style>